import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Flex,
  Box,
  Text,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useToast,
} from "@chakra-ui/react";
import { FiCheckCircle } from "react-icons/fi";
import theme from "../../global/theme";

import Pagination from "../../shared/pagination/Pagination";

import { getSafkaOrders } from "../../../store/orders/ordersSlice";
import CopyToClipboard from "react-copy-to-clipboard";

const OrdersDrawer = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { safkaOrders } = useSelector((state) => state.orders);
  const toast = useToast({ status: "success", position: "top" });
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getSafkaOrders({ page }));
  }, [dispatch, page]);

  return (
    <Drawer
      isOpen={isOpen}
      placement="left"
      // size={{ base: "xs", md: "sm" }}
      onClose={onClose}
    >
      <DrawerOverlay>
        <DrawerContent px={2}>
          <DrawerHeader borderBottomWidth="1px">
            <Flex justifyContent="space-between" alignItems="center">
              <Text>الطلبات</Text>
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            {safkaOrders.data?.itemsCount > 0 ? (
              safkaOrders.data?.data?.map((item) => (
                <Box
                  key={item._id}
                  p={4} // Add padding
                  m={2} // Add margin for spacing between items
                  borderRadius={8} // Round the corners
                >
                  <CopyToClipboard
                    text={item.serial_number}
                    onCopy={() => {
                      toast({
                        position: "top",
                        description: "تم نسخ كود الطلب ",
                      });
                    }}
                  >
                    <Box color="#5fd2ff" cursor={"pointer"}>
                      {item.serial_number}
                    </Box>
                  </CopyToClipboard>
                </Box>
              ))
            ) : (
              <Flex
                textAlign="center"
                bg={theme.light}
                height={200}
                alignItems="center"
                boxShadow={theme.shadow}
                justifyContent="center"
                borderRadius={16}
              >
                <Text
                  fontSize={18}
                  textTransform="capitalize"
                  color="gray.300"
                  fontWeight="bold"
                >
                  لا توجد طلبات
                </Text>
              </Flex>
            )}
          </DrawerBody>
          {/* <DrawerFooter>
            <Pagination
              page={page}
              itemsCount={safkaOrders.data?.itemsCount ?? 0}
              onChange={(page) => setPage(page)}
            />
          </DrawerFooter> */}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default OrdersDrawer;
