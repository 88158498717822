import React, { useState } from "react";
import {
  IconButton,
  Box,
  Text,
  VStack,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaWhatsapp, FaUsers } from "react-icons/fa";
import { BsCartPlusFill } from "react-icons/bs";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import CreateModal from "../../modules/orders/actions/CreateModal";

// Motion components for animation
const MotionDiv = motion.div;
const MotionVStack = motion(VStack);

const SupportButton = () => {
  const [showOptions, setShowOptions] = useState(false);
  const supports = useSelector((state) => state.supports);

  // Dynamic values based on screen width
  const isLargeScreen = useBreakpointValue({ base: false, lg: true });
  const icon = isLargeScreen ? <BsCartPlusFill /> : <FaWhatsapp />;

  return (
    <MotionDiv
      drag="x"
      dragConstraints={{
        left: -window.innerWidth + 100,
        right: 0,
      }}
      style={{
        position: "fixed",
        zIndex: 9999,
        bottom: "30px",
        right: "20px",
      }}
    >
      {/* Support button */}
      <motion.div
        style={{
          zIndex: 9999,
          bottom: "30px",
        }}
      >
        <IconButton
          icon={icon}
          aria-label="Support"
          color="white"
          size="lg"
          variant="solid"
          bg="linear-gradient(45deg, #25d366, #128c7e)"
          _hover={{
            bg: "linear-gradient(45deg, #128c7e, #25d366)",
          }}
          _active={{
            bg: "linear-gradient(45deg, #1c6e56, #0b5e46)",
          }}
          borderRadius="full"
          onClick={() => setShowOptions(!showOptions)}
          boxShadow="0px 4px 12px rgba(0, 0, 0, 0.2)"
        />
      </motion.div>

      {/* CreateModal for large screens */}
      {isLargeScreen && showOptions && (
        <CreateModal onClose={() => setShowOptions(false)} />
      )}

      {/* Options list */}
      {showOptions && (
        <MotionVStack
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
          align="start"
          spacing={5}
          bg="white"
          p={5}
          boxShadow="lg"
          borderRadius="lg"
          position="absolute"
          bottom="50px"
          right="0"
          width="200px"
          display={{ base: "flex", md: "none" }}
        >
          {supports.data?.data?.map((el) => (
            <Box
              key={el.phone}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={4}
              _hover={{
                bg: "#f1f1f1",
                borderRadius: "lg",
                cursor: "pointer",
              }}
              p={3}
              transition="all 0.3s ease-in-out"
            >
              <FaUsers size={20} className="text-green-500" />
              <Text fontSize="sm" fontWeight="bold">
                <Link
                  href={`https://wa.me/2${el.phone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green-500"
                  _hover={{
                    textDecoration: "underline",
                  }}
                >
                  {el?.description}
                </Link>
              </Text>
            </Box>
          ))}
        </MotionVStack>
      )}
    </MotionDiv>
  );
};

export default SupportButton;
